.wrapper {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: var(--background-02);
  color: var(--text-color-1);
}

.container {
  width: 100%;
  height: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 4rem 1rem;
}

.settings {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 3rem;
  margin-top: 3rem;
}

.settings > div {
  background: var(--background-04);
  border-radius: 0.4rem;
  padding: 1rem;
}

.settings > div > h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.settings > div > p {
  margin-bottom: 2rem;
}

.button {
  background: var(--background-02);
  color: var(--text-color-1);
  font-size: 1rem;
  padding: 1rem 2rem;
  border-radius: 0.4rem;
  outline: none;
  border: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  transition: 0.1s;
}

.button .icon {
  margin-left: 0.8rem;
}

.button:hover {
  opacity: 1;
}