.wrapper {
    height: 100%;
    min-height: 100vh;
    background: var(--background-02);
    color: var(--text-color-1);
}

.container {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    text-align: center;
}

.content {
    font-size: 1rem;
    margin-top: 8rem;
}

.content > h1 {
    font-size: 3rem;
    text-transform: uppercase;
}

.content > h2 {
    font-size: 1.5rem;
    font-weight: 500;
}

.content > p {
    font-size: 1.2rem;
    font-weight: 100;
    margin-top: 5rem;
}

.content > a {
    font-size: 1rem;
}