@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif !important;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  scrollbar-color: #303030 #111;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}

::selection {
  color: #f2f2f2;
  background: #eb3838;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background-color: #111;
}

body::-webkit-scrollbar-thumb {
  background-color: #eb3838;
  border-radius: 8px;
}

a {
  color: #acacac;
  text-decoration: underline;
}

.btn-default {
  padding: 1rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.4rem;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.2s;
  background: #eb3838;
  color: #f2f2f2;
  text-decoration: none;
}

.btn-default:hover,
.btn-default:focus {
  background: #e63131;
  color: #f2f2f2;
}

.btn-default-outline {
  padding: 1rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.4rem;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.2s;
  background: #eb3838;
  color: #eb3838;
  text-decoration: none;
  background: transparent;
  border: 0.1rem solid #e63131;
}

.btn-default-outline:hover,
.btn-default-outline:focus {
  border-color: #f53a3a;
  color: #f53a3a;
}

.theme-light {
  --color-brand: #eb3838;
  --background-01: #e7e7e7;
  --background-02: #e2e2e2;
  --background-03: #d8d8d8;
  --background-04: #eef0f2;
  --background-05: #9e9e9e;
  --text-color-1: #000000;
  --text-color-2: #ffffff;
}

.theme-dark {
  --color-brand: #eb3838;
  --background-01: #000000;
  --background-02: #181818;
  --background-03: #181818;
  --background-04: #282828;
  --background-05: #000000;
  --text-color-1: #ffffff;
  --text-color-2: #000000;
}

.spinAnimate {
  animation-name: spin;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
