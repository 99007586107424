.container {
    border-radius: 0.4rem;
    color: var(--color-04);
}

.video {
    border-radius: 1rem;
    margin: 1.8rem 0;
    aspect-ratio: 16 / 9;
    width: 100%;
    border: none;
    box-shadow: 0px 0px 80px;
    color: var(--background-04);
}

.title {
    margin-top: 1rem;
    font-size: 1.3rem;
    text-align: center;
}

.video_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    background: var(--background-04);
    border-radius: 0.5rem;
    padding: 1rem;
    font-size: 1rem;
    gap: 1rem;
}


.video_footer h1 {
    font-weight: normal;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.video_footer h1 .icon {
    color: var(--color-brand);
    font-size: 1.2rem;
    margin-top: 0.1rem;
    margin-left: 0.1rem;
}

@media (max-width: 40rem) {
    .video_footer {
        flex-direction: column-reverse;
    }
}