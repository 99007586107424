.container {
    display: inline-flex;
    align-items: center;
    width: 100%;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.text {
    width: 20%;
    font-size: 1rem;
    color: var(--text-color-1);
    text-align: center;
}

.progress {
    display: flex;
    width: 80%;
    height: 14px;
    background: var(--background-04);
    border-radius: 0.8rem;
}

.bar {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-brand);
    border-radius: 0.8rem;
    transition-property: width;
    transition-duration: 0.5s;
}

