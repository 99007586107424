.navbar {
  display: block;
  background: var(--background-01);
  height: 4rem;
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--background-05);
}

.navbar .container {
  height: 100%;
  width: 100%;
  max-width: 1600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.navbar .brand {
  height: 100%;
  width: 20%;
  display: flex;
  align-items: center;
  color: var(--color-brand);
  font-size: 1.5rem;
  padding: 1rem;
}

.navbar .brand>a>img {
  height: 100%;
  max-height: 2.5rem;
}

.navbar .navLinks {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  gap: 3rem;
  font-size: 1rem;
  position: initial;
  padding: 0 1rem;
}

.navbar .navLink>a,
.navbar .navLink>button {
  color: var(--text-color-1);
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  font-size: 1rem;
  transition: 0.1s;

  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  opacity: 0.895;
}

.navbar .navLink .icon {
  color: var(--text-color-1);
  font-size: 1.5rem;
  margin-right: 0.5rem;
  opacity: 0.895;
}

.navbar .navLink>a:hover,
.navbar .navLink>button:hover,
.navbar .navLink>a:hover .icon,
.navbar .navLink>button:hover .icon {
  opacity: 1;
}

.navbar .profile {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #414040;
  color: #fff;
  padding: 1rem;
  margin: 0;
}

.navbar .profile .icon {
  margin-right: 0.5rem;
}

.btnMobileMenu {
  display: none;
  cursor: pointer;
  border: none;
  border-radius: 0.4em;
  outline: none;
  box-shadow: none;
  background: var(--background-02);
  color: var(--text-color-1);
  margin-right: 1rem;
  font-size: 2rem;
  height: 3rem;
  padding: 0 1.5rem;
}

@media (max-width: 40rem) {
  .navbar .navLinks {
    background: var(--background-02);
    border: 1px solid var(--background-05);
    flex-direction: column;
    position: absolute;
    top: 4rem;
    height: auto;
    width: 100%;
    z-index: 1;

    display: none;
    pointer-events: none;
    align-items: initial;
    gap: 1rem;
    padding: 1.5rem 1rem;
  }

  .navbar .navLink>a,
  .navbar .navLink>button {
    background: var(--background-01);
    border-radius: 0.4rem;
    height: 4rem;
    width: 100%;
  }

  .navbar .profile {
    border-radius: 0.4rem;
    background: transparent;
    color: var(--text-color-1);
  }

  .navbar .navLinks.acitve {
    display: flex;
    pointer-events: initial;
  }

  .btnMobileMenu {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.icon_verified {
  color: yellow;
  margin-left: 0.5rem;
  font-size: 1.1rem;
}