.wrapper {
  height: 100%;
  min-height: 100vh;

  background: var(--background-02);
  color: var(--text-color-1);

  display: flex;
  justify-content: center;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  border-radius: 0.4rem;
  width: 100%;
  max-width: 1200px;
  margin-top: 2rem;
}

.box > h1 {
  margin-bottom: 2rem;
}

.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
}

.form > input {
  padding: 1rem;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 0.4rem;
  font-size: 1rem;
}

.form > button {
  padding: 1rem;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 0.4rem;
  margin-top: 1em;
  font-size: 1rem;
  cursor: pointer;
  background: var(--color-brand);
  color: #fff;
  opacity: 0.9;
  transition: 0.1s;
}

.form > button:hover {
  opacity: 1;
}
