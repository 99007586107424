.wrapper {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 7rem);
  color: var(--text-color-1);
  background: var(--background-02);
  display: flex;
  justify-content: center;
}

.profile {
  width: 100%;
}

.profileWrapper {
  background: var(--background-01);
  width: 100%;
  height: 20vh;
  position: relative;
  display: flex;
  justify-content: center;
}

.dkjkdjdfjd {
  position: absolute;
  bottom: -10rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.dkjkdjdfjd > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.2rem;
}

.avatar {
  height: 180px;
  width: 180px;
  border-radius: 50%;
  border: 0.2rem solid white;
  object-fit: cover;
}

.username {
  font-size: 20rem !important;
  color: #000;
}

.nameUser {
  text-transform: capitalize;
}
